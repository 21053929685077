import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import backgroundVideo from '../assets/videos/Kerecz_Final_4K.mp4'; // Background video
import contentVideo from '../assets/videos/Kerecz_Final_4K_FULL.mp4'; // Content video
import Artist_picture from '../assets/pictures/Artist.JPG';
import Jewelry_picture from '../assets/pictures/Jewelry.jpg';
import Atelier_picture from '../assets/pictures/Atelier.JPG';
import Trend_picture from '../assets/pictures/Trend.JPG';
import Factory_picture from '../assets/pictures/Factory.JPG';
import DownwardArrow from '../assets/Custom_Elements/DownwardArrow.js';
import './Home.css';
import CustomLink from '../assets/Custom_Elements/CustomLink';

const Home = ({ showOverlay }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [arrowOpacity, setArrowOpacity] = useState(1);

  const sections = document.querySelectorAll('.spaced-text');

  useEffect(() => {
    // Cache sections once to avoid repeated DOM queries
    const sections = document.querySelectorAll('.spaced-text');

    const handleScroll = () => {
      let closestSectionIndex = -1;
      let closestDistance = Number.MAX_VALUE;

      const offset = window.innerHeight * 0.5;

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        const middle = rect.top + rect.height / 2; // Calculate the middle point of the section
        const distance = Math.abs(middle - offset); // Distance from the section middle to the desired offset
        

        if (distance < closestDistance) {
          closestDistance = distance;
          closestSectionIndex = index;
        }
      });

      if (closestSectionIndex !== -1) {
        setActiveSection(closestSectionIndex);
      }


      // Calculate scroll progress once
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const maxScrollHeight =
        document.documentElement.scrollHeight - windowHeight;
      const scrollProgress = Math.min(scrollY / maxScrollHeight, 1);


      // Arrow fade logic
      const arrowFadeStart = 0;
      const arrowFadeEnd = 0.1;
      if (scrollProgress >= arrowFadeStart) {
        const arrowOpacity = Math.max(0, 1 - (scrollProgress - arrowFadeStart) / (arrowFadeEnd - arrowFadeStart));
        setArrowOpacity(arrowOpacity);
      } else {
        setArrowOpacity(1);
      }
    };

    const debouncedHandleScroll = () => {
      window.requestAnimationFrame(handleScroll);
    };



    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, []);


  const scrollToSection = (index) => {
    const section = document.getElementById(`section-${index}`);
    if (section) {

      const yOffset = window.innerHeight * 0.25;

      const y = section.getBoundingClientRect().top + window.scrollY - yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };



  return (
    <div className="home">


      <div className="scroll-indicator">
        <div
          className={`dot ${activeSection === 0 ? 'active' : ''}`}
          onClick={() => scrollToTop()}
        ></div>
        <div
          className={`dot ${activeSection === 1 ? 'active' : ''}`}
          onClick={() => scrollToSection(1)}
        ></div>
        <div
          className={`dot ${activeSection === 2 ? 'active' : ''}`}
          onClick={() => scrollToSection(2)}
        ></div>
        <div
          className={`dot ${activeSection === 3 ? 'active' : ''}`}
          onClick={() => scrollToSection(3)}
        ></div>
        <div
          className={`dot ${activeSection === 4 ? 'active' : ''}`}
          onClick={() => scrollToSection(4)}
        ></div>
        <div
          className={`dot ${activeSection === 5 ? 'active' : ''}`}
          onClick={() => scrollToSection(5)}
        ></div>
        <div
          className={`dot ${activeSection === 6 ? 'active' : ''}`}
          onClick={() => scrollToSection(6)}
        ></div>
      </div>


      <div className='main-container'>
        <div className='background-container'>
          <DownwardArrow isVisible={true} opacity={arrowOpacity} />
          <div className='video-container'>
            <video
              className="background-video"
              autoPlay
              loop
              muted
            >
              <source src={backgroundVideo} preload="auto" type="video/mp4" />
              Your browser does not support the video tag.

            </video>
            <div className='video-mask'></div>
          </div>
        </div>
        <div className='header-text'>
          <div className='spaced-text title' id='section-0'>Home</div>
        </div>


        <div className='home-scrollable-content'>

          <div className="spaced-text" id="section-1">
            <div className='title-text'>Video</div>
            <div className="video-content-container">

              <video preload="auto" className="content-video" controls>
                <source src={contentVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

            </div>
          </div>


          <div className="spaced-text" id="section-2">
            <div className='title-text'><CustomLink to="/artist" showOverlay={showOverlay}>Artist</CustomLink></div>
            <CustomLink to="/artist" showOverlay={showOverlay}>
              <img src={Artist_picture} loading="eager" alt='Artist' className="image-container" />
            </CustomLink>
          </div>

          <div className="spaced-text" id="section-3">
            <div className='title-text'><CustomLink to="/jewelry" showOverlay={showOverlay}>Jewelry</CustomLink></div>
            <CustomLink to="/jewelry" showOverlay={showOverlay}>
              <img src={Jewelry_picture} loading="eager" alt='Jewelry' className="image-container" />
            </CustomLink>
          </div>

          <div className="spaced-text" id="section-4">
            <div className='title-text'><CustomLink to="/trend" showOverlay={showOverlay}>Trend</CustomLink></div>
            <CustomLink to="/trend" showOverlay={showOverlay}>
              <img src={Trend_picture} loading="eager" alt='Trend' className="image-container" />
            </CustomLink>
          </div>

          <div className="spaced-text" id="section-5">
            <div className='title-text'><CustomLink to="/atelier" showOverlay={showOverlay}>Atelier</CustomLink></div>
            <CustomLink to="/atelier" showOverlay={showOverlay}>
              <img src={Atelier_picture} loading="eager" alt='Atelier' className="image-container" />
            </CustomLink>
          </div>

          <div className="spaced-text" id="section-6">
            <div className='title-text'><CustomLink to="/factory" showOverlay={showOverlay}>Factory</CustomLink></div>
            <CustomLink to="/factory" showOverlay={showOverlay}>
              <img src={Factory_picture} loading="eager" alt='Factory' className="image-container" />
            </CustomLink>
          </div>




        </div>


      </div>


    </div>
  );
};

export default Home;