import React from "react";
import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top"></div>
      <div className="footer-content">
        <div className="contact-info">
          <div className="info-box">
            <p className="email-title"><strong>EMAIL & TELEFON</strong></p>
            <div className="content">
              <p><a href="mailto:juwelenschmiede@kerecz.at">juwelenschmiede@kerecz.at</a></p>
              <p><a href="tel:+436642533419">+43 664 2533419</a></p>
            </div>
          </div>
        </div>
        <div className="address">
          <div className="info-box">
            <p className="address-title"><strong>ADRESSE</strong></p>
            <div className="content">
              <p>Rudolf-Krenn-Gasse 146a</p>
              <p>8322 Südstudenzen, Österreich</p>
            </div>
          </div>
        </div>
        <div className="social-links">
          <div className="info-box">
            <p className="kontakt-title"><strong>KONTAKT</strong></p>
            <div className="content">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i> {/* White Facebook Icon */}
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i> {/* White Instagram Icon */}
              </a>
            </div>
          </div>

        </div>
      </div>
      <div className="footer-bottom">
        <p>Alle Rechte vorbehalten © Anton Kerecz 2024</p>
        <div>
          <a href="/datenschutz">Datenschutz</a> <a href="/impressum">Impressum</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
