import React from 'react';
import './TrendDetailButton.css';

const TrendDetailButton = ({ onClick }) => {
  return (
    <div className="TrendDetail-button-container" onClick={onClick}>
      <div className="TrendDetail-button">
        <div className="TrendDetail-arrow">
          <div className="TrendDetail-arrow-line"></div>
          <div className="TrendDetail-arrow-head"></div>
        </div>
        
      </div>
      <div className="TrendDetail-overlay"></div>
    </div>
  );
};

export default TrendDetailButton;
