import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logoBright from '../pictures/logo_white.png';
import logoDark from '../pictures/logo.png';
import CustomLink from './CustomLink';
import './Logo.css';

const Logo = ({ isMenubarVisible, showOverlay }) => {
    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [forceLogoDark, setForceLogoDark] = useState(false);
    const [menuBarVisible, setMenuBarVisible] = useState(isMenubarVisible); // Local state for isMenubarVisible
    const location = useLocation();

    // Determine if the logo should be visible based on the current path
    const logovisible = location.pathname !== '/trend-detail';

    // Sync the local menu bar state with the parent prop
    useEffect(() => {
        setMenuBarVisible(isMenubarVisible);
    }, [isMenubarVisible]);

    // Handle scrolling and update scrolling state
    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            setIsScrollingDown(true); // User is scrolling down
        } else {
            setIsScrollingDown(false); // User is scrolling up
        }
        setLastScrollY(window.scrollY); // Update the last scroll position

        // Check if user is at the top of the jewelry page
        if (location.pathname === '/jewelry' && window.scrollY === 0) {
            setForceLogoDark(true); // Force dark logo at the top of the jewelry page
        } else {
            setForceLogoDark(false); // Disable forced dark logo otherwise
        }
    };

    // Handle scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY, location.pathname]);

    // Check on initial load if the logo should be forced to dark
    useEffect(() => {
        if (location.pathname === '/jewelry' && window.scrollY === 0) {
            setForceLogoDark(true);
        } else {
            setForceLogoDark(false);
        }
    }, [location.pathname]);

    return (
        logovisible && (
            <div className="logo-container">
                <CustomLink to="/" showOverlay={showOverlay}>
                    {/* Bright logo should only be visible when menubar is visible and the user is not scrolling down */}
                    <img
                        src={logoBright}
                        alt="Bright Logo"
                        className={`logo ${(menuBarVisible || !isScrollingDown) && !forceLogoDark ? 'visible' : 'hidden'}`}
                    />
                    {/* Dark logo should only be visible when the user is scrolling down or when forced */}
                    <img
                        src={logoDark}
                        alt="Dark Logo"
                        className={`logo ${(isScrollingDown && !menuBarVisible) || forceLogoDark ? 'visible' : 'hidden'}`}
                    />
                </CustomLink>
            </div>
        )
    );
};

export default Logo;
