import React, { useEffect, useState, useRef } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import products from '../products/products';
import CategorySelector from '../assets/Custom_Elements/CategorySelector';
import ProductCatalog from '../assets/Custom_Elements/ProductCatalog';
import './Jewelry.css';
import ProductPage from './ProductPage';

const Jewelry = () => {
  const [selectedCategory, setSelectedCategory] = useState('Ringe');
  const categories = ['Ringe', 'Halsketten', 'Armbänder', 'Ohrringe'];
  const navigate = useNavigate();
  const [id, setId] = useState(0);
  const filteredProducts = products.filter(product => product.category === selectedCategory);
  const [hideProductCatalog, setHideProductCatalog] = useState(false);
  const [hideProductDetails, setHideProductDetails] = useState(true);
  const [transition, setTransition] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const jewelryType = params.get('type'); // Extracts the 'type' parameter

  // Create a ref to scroll to the product page
  const productPageRef = useRef(null);

  const switchProduct = (id) => {
    if (id != null) {
      setTransition(true);
      window.scrollTo({
        top: window.innerHeight * 0.2,
        behavior: 'smooth',
      });
      
      setTimeout(() => {
        setHideProductCatalog(true);
        setId(id);
        setHideProductDetails(false);

      }, 700);

      setTimeout(() => {
        setTransition(false);
      }, 1000);


    }
  };

  const setCategory = (any) => {
    setTransition(true);
    setTimeout(() => {
      setSelectedCategory(any);
      setHideProductDetails(true);
      setHideProductCatalog(false);
      setId(0);
    }, 500);

    setTimeout(() => {
      setTransition(false);
    }, 700);
  };

  useEffect(() => {
    if (jewelryType != null) {
      setSelectedCategory(jewelryType);
    }
  }, []);

  return (
    <div className="jewelry">
      <div className="jewelry-container">

        <div className='jewelry-content-container'>
          <CategorySelector
            categories={categories}
            selectedCategory={selectedCategory}
            onCategoryChange={setCategory}
          />

          <div className="content-container" ref={productPageRef}>
            <div className={`transition-overlay ${transition ? 'show' : ''}`}></div>
            <ProductCatalog products={filteredProducts} isHidden={hideProductCatalog} onSelectProduct={switchProduct} />

            <ProductPage id={id} products={products} isHidden={hideProductDetails} />
            <div className={`jewelry-header-text ${transition || id != 0 ? 'hide' : ''}`}><b>{selectedCategory}</b></div>
             </div>
        </div>
      </div>
    </div>
  );
};

export default Jewelry;
