import React from "react";
import "./DesignMainSites.css";
import Factory_picture0 from '../assets/pictures/Factory/Factory0.JPG';
import Factory_picture1 from '../assets/pictures/Factory/Factory1.JPG';
import Factory_picture2 from '../assets/pictures/Factory/Factory2.JPG';
import Factory_picture3 from '../assets/pictures/Factory/Factory3.JPG';
import Factory_picture4 from '../assets/pictures/Factory/Factory4.JPG';
import Factory_picture5 from '../assets/pictures/Factory/Factory5.JPG';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { easeInOut, easeOut } from "framer-motion";

const Factory = () => {
  return (
    <ParallaxProvider>
      <div className="content-main">

        <Parallax className="parallax-header" speed={0} easing={easeInOut}>
          <img src={Factory_picture0} className="parallax-image" alt="Hero"  />
          <div className="mainsites-header-text factory">
            <h1>Factory</h1>
          </div>
        </Parallax>

        <Parallax speed={50} className="parallax-content" easing={easeInOut}>
          <div className="mainsiteswrapper">
            <section className="mainsites-content-section">
              <section className="content-section vertical-layout">
                <div className="text-block">
                  <h2>Handmade for…
                  </h2>
                </div>
                <div className="image-block vertical">
                  <img src={Factory_picture1} alt="Hero" />
                </div>
                <div className="text-block">
                  <p style={{ maxWidth: "60%" }}>Schwelgen Sie in der Auswahl. Genießen Sie das Funkeln. Verlieben Sie sich in
                    jedes einzelne Schmuckstück. Aber glauben Sie bloß nicht das wäre alles! Saisonale
                    Kollektionen und Maßanfertigungen bringen Ihre Wünsche und unsere Kreativität
                    ans Tageslicht. Fordern Sie Anton Kerecz heraus!
                  </p></div>
              </section>


              <div className="yellowBackground factory">
                <section className="content-section">
                  <div className="text-block">
                    <h2>Leidenschaft und Kunst
                    </h2>
                    <p>
                      Manchmal reichen Worte nicht, um Gefühle vollends zu
                      beschreiben. Da Bedarf es eines anderen Metiers: der
                      Kunst. Individuelle Anfertigungen schaffen diese Brücke
                      zwischen Herz und Ausdruck. Sprechen Sie mit Anton
                      Kerecz und er wird mit Ihnen das ultimative Design
                      entwickeln.
                    </p>
                  </div>
                  <div className="image-block overflow">
                    <img src={Factory_picture2} alt="Content 2" className="overflow-image" />
                  </div>
                </section>
              </div>

              <section className="content-section">
                <div className="image-block">
                  <img src={Factory_picture3} alt="Rough Diamond" />
                </div>
                <div className="text-block">
                  <h2>Leidenschaft und Kunst
                  </h2>
                  <p style={{ maxWidth: "80%" }}>
                    Manchmal reichen Worte nicht, um Gefühle vollends zu
                    beschreiben. Da Bedarf es eines anderen Metiers: der
                    Kunst. Individuelle Anfertigungen schaffen diese Brücke
                    zwischen Herz und Ausdruck. Sprechen Sie mit Anton
                    Kerecz und er wird mit Ihnen das ultimative Design
                    entwickeln.

                  </p>
                </div>
              </section>

              <div className="yellowBackground">
                <section className="content-section yellow">
                  <div className="text-block">
                    <h2>Leidenschaft und Kunst</h2>
                    <p>
                      Manchmal reichen Worte nicht, um Gefühle vollends zu
                      beschreiben. Da Bedarf es eines anderen Metiers: der
                      Kunst. Individuelle Anfertigungen schaffen diese Brücke
                      zwischen Herz und Ausdruck. Sprechen Sie mit Anton
                      Kerecz und er wird mit Ihnen das ultimative Design
                      entwickeln.
                    </p>
                  </div>
                  <div className="image-block overflow">
                    <img src={Factory_picture4} alt="Placeholder for artist" className="overflow-image" />
                  </div>
                </section>
              </div>


              <section className="content-section vertical-layout">
                <div className="image-block vertical">
                  <img src={Factory_picture5} alt="Placeholder for artist" />
                </div>
                <div className="text-block">
                  <h2>Leidenschaft und Kunst</h2>
                  <p>
                    Ein Schmuckstück von Anton Kerecz begleitet Sie ein Leben lang. Ob Hochzeit, Ball,
                    Arbeit, Reisen, im Garten oder Schaumbad - Sie zeigen Charakter.
                  </p>
                </div>
              </section>
            </section>
          </div>
        </Parallax>
      </div>

    </ParallaxProvider>
  );
};

export default Factory;
