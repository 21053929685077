import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function CustomLink({ to, showOverlay, onMouseEnter, children }) {
  const location = useLocation();

  const handleClick = (e) => {
    
    e.preventDefault(); // Prevent default navigation behavior if needed

    // Only trigger `onLinkClick` if the destination is different from the current path
    if (location.pathname !== to) {
      
      if (showOverlay && showOverlay.current) {
        showOverlay.current();
        
        setTimeout(() => {
          window.scrollTo(0, 0); // Scroll to the top of the page
        }, 200); // Adjust delay as necessary for transition effect

        setTimeout(() => {
          window.location.href = to; // Navigate after delay
        }, 1000); // Adjust delay as necessary for transition effect
      }


    }
  };
  
  return (
    <Link
      to={to}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </Link>
  );
}

export default CustomLink;
