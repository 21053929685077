// src/data/products.js
const products = [
  {
    id: 1,
    name: 'Eheringe',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 1.psd'),
    description: 'Eheringe,  18Kt Weißgold, Damenring mit 105 Brillanten von insgesamt 0,82 Karat; Herrenring seidenmatt'
  },
  {
    id: 2,
    name: 'Verlobungsring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 2.jpg'),
    description: 'Verlobungsring, 18Kt Weißgold mit einem Diamanten in der Mitte im Prinzess-Schliff von 0,90 Karat und 8 Diamanten mit Prinzess-Schliff zieren die Schiene'
  },
  {
    id: 3,
    name: 'Perlenring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 3.jpg'),
    description: 'Perlenring, 18Kt Weißgold mit einer Südseeperle 17-18mm und 2 Brillanten von insgesamt 0,34 Karat'
  },
  {
    id: 4,
    name: 'Breiter Ring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 4.JPG'),
    description: 'Breiter Ring, 18Kt Roségold mit Rauten-Gravur, Breite 12,5mm'
  },
  {
    id: 5,
    name: 'Cocktailring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 5.JPG'),
    description: 'Cocktailring, 18Kt Weißgold mit einem 10,53 Karat Aquamarin Größe 16,0x12,5mm und Diamanten mit Prinzess-Schliff 0,44 Karat zieren die Schiene'
  },
  {
    id: 6,
    name: 'Cocktailring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 6.JPG'),
    description: 'Cocktailring, 18Kt Weißgold mit einem 4,26 Karat gelben Saphir Durchmesser 9,5mm und Pavé-Brillanten 1,20 Karat zieren die Schiene'
  },
  {
    id: 7,
    name: 'Memoryring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 7.JPG'),
    description: 'Memoryring, 18Kt Weißgold mit 22 Brillanten von insgesamt 2,34 Karat, Schienenbreite 3,5mm'
  },
  {
    id: 8,
    name: 'Memoryring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 8.JPG'),
    description: 'Memoryring, 18Kt Roségold mit 23 schwarzen Diamanten von insgesamt 3,4 Karat, Schienenbreite 3,3mm'
  },
  {
    id: 9,
    name: 'Memoryring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 9.JPG'),
    description: 'Memoryring, 18Kt Roségold Schwarz rhodiniert mit 31 braunen Brillanten von insgesamt 1,0 Karat, Schienenbreite 2,8mm'
  },
  {
    id: 10,
    name: 'Memoryring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 10.JPG'),
    description: 'Memoryring, 18Kt Geldgold mit 31 Brillanten von insgesamt 1,0 Karat, Schienenbreite 2,8mm'
  },
  {
    id: 11,
    name: 'Memoryringe',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 11.JPG'),
    description: 'Memoryringe, 18Kt Gelbgold und Roségold mit je 39 bestrahlten Brillanten in Gelb, Blau und Pink, Schienenbreite 2,0mm'
  },
  {
    id: 12,
    name: 'Perlenring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 12.JPG'),
    description: 'Perlenring, 18Kt Weißgold mit einer Südseeperle 10,5mm und 16 Brillanten von insgesamt 0,19 Karat zieren die Schiene'
  },
  {
    id: 13,
    name: 'Perlenring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 13.JPG'),
    description: 'Perlenring, 18Kt Weißgold Schwarz rhodiniert mit einer Tahitiperle 10,5mm und 16 schwarze Diamanten von insgesamt 0,20 Karat zieren die Schiene'
  },
  {
    id: 14,
    name: 'Verlobungsring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 14.JPG'),
    description: 'Verlobungsring, 18Kt Weißgold mit einem Diamanten im Emerald Cut von 1,0 Karat und 14 Brillanten von insgesamt 0,31 Karat zieren die Schiene'
  },
  {
    id: 15,
    name: 'Verlobungsring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 15.JPG'),
    description: 'Verlobungsring, 18Kt Weißgold  6-fache Krappenfassung mit einem Brillanten von 1,01 Karat, ein All-Time-Klassiker'
  },
  {
    id: 16,
    name: 'Verlobungsring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 16.JPG'),
    description: 'Verlobungsring, 18Kt Weißgold 6-fache Krappenfassung mit einem Brillanten von 0,54 Karat und 16 Brillanten von insgesamt 0,23 Karat zieren die Schiene'
  },
  {
    id: 17,
    name: 'Verlobungsring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 17.JPG'),
    description: 'Verlobungsring, 18Kt Weißgold mit einem Diamanten im Prinzess-Schliff von 0,71 Karat'
  },
  {
    id: 18,
    name: 'Verlobungsring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 18.JPG'),
    description: 'Verlobungsring, 18Kt Roségold mit 3 Brillanten von insgesamt 0,56 Karat '
  },
  {
    id: 19,
    name: 'Verlobungsring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 19.JPG'),
    description: 'Verlobungsring, 18Kt Roségold mit einem Brillanten von 0,52 Karat'
  },
  {
    id: 20,
    name: 'Cocktailring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 20.JPG'),
    description: 'Cocktailring, 18Kt Weißgold mit einem 2,53 Karat Aquamarin Größe 9,0mm und 40 Brillanten von insgesamt 0,52 Karat zieren die Schiene'
  },
  {
    id: 21,
    name: 'Diamantring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 21.JPG'),
    description: 'Diamantring, 18Kt Gelbgold mit 5 Brillanten von insgesamt 0,36 Karat'
  },
  {
    id: 22,
    name: 'Eheringe',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 22.JPG'),
    description: 'Eheringe, 18Kt Roségold und 18Kt Weißgold, der Ring spiegelt die Facetten des Lebens wieder, Schienenbreite 3,8mm'
  },
  {
    id: 23,
    name: 'Diamantring',
    category: 'Ringe',
    image: require('./Ringe/RingeBilder/Ring 23.psd'),
    description: 'Diamantring, 18Kt Weißgold mit 5 Brillanten von insgesamt 0,73 Karat'
  },
  {
    id: 24,
    name: 'Perlenohrringe ',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 1.psd'),
    description: 'Perlenohrringe , 18Kt Weißgold mit 2 Tahitiperlen 13mm'
  },
  {
    id: 25,
    name: 'Perlenohrclips',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 2.psd'),
    description: 'Perlenohrclips, 18Kt Weißgold mit 50 Brillanten von insgesamt 0,42 Karat und 2 Mabe-Zuchtperlen 13mm'
  },
  {
    id: 26,
    name: 'Ohrstecker',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 3.JPG'),
    description: 'Ohrstecker, 18Kt Weißgold mit 2 Brillanten von insgesamt 1,10 Karat'
  },
  {
    id: 27,
    name: 'Ohrstecker',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 4.JPG'),
    description: 'Ohrstecker, 18Kt Weißgold mit 18 Brillanten von insgesamt 0,57 Karat, Durchmesser 6,5mm'
  },
  {
    id: 28,
    name: 'Ohrstecker',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 5.JPG'),
    description: 'Ohrstecker, 18Kt Weißgold mit 2 Aquamarinen von 1,97 Karat und 32 Brillanten von insgesamt 0,26 Karat, Durchmesser 9,3mm'
  },
  {
    id: 29,
    name: 'Ohrstecker',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 6.JPG'),
    description: 'Ohrstecker, 18Kt Roségold mit 2 Brillanten von 0,62 Karat und 24 Brillanten von insgesamt 0,32 Karat, Durchmesser 8,5mm'
  },
  {
    id: 30,
    name: 'Ohrstecker',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 7.JPG'),
    description: 'Ohrstecker, 18Kt Roségold mit 32 Brillanten von insgesamt 0,31 Karat, Durchmesser 9mm'
  },
  {
    id: 31,
    name: 'Creolen',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 8.JPG'),
    description: 'Creolen, 18Kt Weißgold mit 80 Brillanten von insgesamt 0,74 Karat, Durchmesser 13,7mm, Breite 6,7mm'
  },
  {
    id: 32,
    name: 'Creolen',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 9.JPG'),
    description: 'Creolen, 18Kt Roségold mit 34 Brillanten von insgesamt 0,94 Karat, Durchmesser 17,5mm, Breite 4,8mm'
  },
  {
    id: 33,
    name: 'Creolen',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 10.JPG'),
    description: 'Creolen, 18Kt Geldgold mit 20 Brillanten von insgesamt 0,27 Karat, Durchmesser 10,7mm, Breite 1,9mm'
  },
  {
    id: 34,
    name: 'Ohrstecker',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 11.JPG'),
    description: 'Ohrstecker, 18Kt Roségold mit 10 Brillanten von insgesamt 0,25 Karat, Durchbesser 8mm, Breite 2mm'
  },
  {
    id: 35,
    name: 'Ohrringe',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 12.JPG'),
    description: 'Ohrringe, 18Kt Gelbgold mit 2 Topasen von 7,03 Karat, Topas Durchmesser 8,1mm'
  },
  {
    id: 36,
    name: 'Perlenohrringe ',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 13.JPG'),
    description: 'Perlenohrringe , 18Kt Weißgold mit 2 Tahitiperlen 13mm und 18 Brillanten von insgesamt 0,48 Karat, Gesamtlänge 41mm'
  },
  {
    id: 37,
    name: 'Ohrringe',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 14.JPG'),
    description: 'Ohrringe, 18Kt Weißgold mit 36 Brillanten von insgesamt 1,25 Karat, Länge 41,5mm'
  },
  {
    id: 38,
    name: 'Ohrringe',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 15.JPG'),
    description: 'Ohrringe, 18Kt Weißgold mit 2 Türkis Tropfen und 74 Brillanten von insgesamt 2,40 Karat, Breite 16,5mm, Gesamtlänge 49,5mm'
  },
  {
    id: 39,
    name: 'Perlenohrringe',
    category: 'Ohrringe',
    image: require('./Ohrringe/OhrringeBilder/Ohrringe 16.JPG'),
    description: 'Perlenohrringe, 18Kt Weißgold mit 2 Südseeperlen 14mm und 2 Brillanten von insgesamt 1,10 Karat'
  },
  {
    id: 40,
    name: 'Halskette mit Tahitiperle',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 1.JPG'),
    description: 'Halskette mit Tahitiperle, 18Kt Weißgold mit 1,5cm Tahitiperle und 7 Brillanten von 0,63 Karat zieren die Kette '
  },
  {
    id: 41,
    name: 'Halskette mit Brillanten',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 2.JPG'),
    description: 'Halskette mit Brillanten, 18Kt Weißgold mit 13 Brillanten insgesamt von 0,47 Karat zieren die Kette'
  },
  {
    id: 42,
    name: 'Halskette mit Anhänger',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 3.JPG'),
    description: 'Halskette mit Anhänger, 18Kt Weißgold mit einem Brillanten von 0,50 Karat'
  },
  {
    id: 43,
    name: 'Halskette mit Anhänger',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 4.JPG'),
    description: 'Halskette mit Anhänger, 18Kt Roségold mit einem ovalen Peridot von 2,63 Karat'
  },
  {
    id: 44,
    name: 'Halskette mit Kreis',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 5.JPG'),
    description: 'Halskette mit Kreis, 18Kt Roségold mit 24 Brillanten von insgesamt 0,12 Karat'
  },
  {
    id: 45,
    name: 'Halskette mit Kreuz',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 6.JPG'),
    description: 'Halskette mit Kreuz, 18Kt Weißgold mit 11 Brillanten von insgesamt 0,11 Karat'
  },
  {
    id: 46,
    name: 'Halskette mit Kreuz',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 7.JPG'),
    description: 'Halskette mit Kreuz, 18Kt Roségold mit 11 schwarzen Diamanten von insgesamt 0,11 Karat'
  },
  {
    id: 47,
    name: 'Halskette mit Kreuz',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 8.JPG'),
    description: 'Halskette mit Kreuz, 18Kt Roségold mit 6 Brillanten von insgesamt 0,19 Karat'
  },
  {
    id: 48,
    name: 'Halskette mit Kreuz',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 9.JPG'),
    description: 'Halskette mit Kreuz, 18Kt Roségold mit 11 Brillanten von insgesamt 0,11 Karat'
  },
  {
    id: 49,
    name: 'Halskette mit Herz- Unendlich',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 10.JPG'),
    description: 'Halskette mit Herz- Unendlich, 18Kt Gelbgold mit 24 Brillanten von insgesamt 0,23 Karat'
  },
  {
    id: 50,
    name: 'Halskette mit Anhänger',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 11.JPG'),
    description: 'Halskette mit Anhänger, 18Kt Weißgold mit 9 Brillanten von insgesamt 0,15 Karat'
  },
  {
    id: 51,
    name: 'Halskette mit Herz',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 12.JPG'),
    description: 'Halskette mit Herz, 18Kt Weißgold mit 16 Brillanten von insgesamt 0,20 Karat'
  },
  {
    id: 52,
    name: 'Halskette mit Herz',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 13.JPG'),
    description: 'Halskette mit Herz, 18Kt Roségold,  Herz Durchmesser 8mm'
  },
  {
    id: 53,
    name: 'Halskette mit Stern',
    category: 'Halsketten',
    image: require('./Halsketten/HalskettenBilder/Kette 14.JPG'),
    description: 'Halskette mit Stern, 18Kt Weißgold mit einem Brillanten von 0,03 Karat '
  },
  {
    id: 54,
    name: 'Kautschuk Armbänder',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 1.psd'),
    description: 'Kautschuk Armbänder, 18Kt Gelbgold und Weißgold mit/ohne 6 Diamanten von insgesamt 0,15 Karat'
  },
  {
    id: 55,
    name: 'Armband mit Kreuz',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 2.JPG'),
    description: 'Armband mit Kreuz, 18Kt Roségold mit 11 schwarzen Diamanten von insgesamt 0,42 Karat an einer handgeknüpften schwarzen Kordel'
  },
  {
    id: 56,
    name: 'Armband mit Schild',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 3.JPG'),
    description: 'Armband mit Schild, 18Kt Weißgold an einer handknüpften schwarzen Kordel'
  },
  {
    id: 57,
    name: 'Armband mit Kreuz',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 4.JPG'),
    description: 'Armband mit Kreuz, 18Kt Roségold mit 6 schwarzen Diamanten von insgesamt 0,22 Karat an einer handgeknüpften schwarzen Kordel'
  },
  {
    id: 58,
    name: 'Armband mit 1er',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 5.JPG'),
    description: 'Armband mit 1er, 18Kt Roségold mit 7 schwarzen Diamanten von insgesamt 0,18 Karat an einer handgeknüpften schwarzen Kordel '
  },
  {
    id: 59,
    name: 'Armband mit Anker',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 6.JPG'),
    description: 'Armband mit Anker, 18Kt Weißgold an einer handgeknüften schwarzen Kordel'
  },
  {
    id: 60,
    name: 'Armband mit Kreuz',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 7.JPG'),
    description: 'Armband mit Kreuz, 18Kt Roségold an einer handgeknüpften schwarzen Kordel'
  },
  {
    id: 61,
    name: 'Armband mit Schild',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 8.JPG'),
    description: 'Armband mit Schild, 18Kt Roségold mit 12 schwarzen Diamanten von insgesamt 0,72 Karat an einer handgeknüpften schwarzen Kordel'
  },
  {
    id: 62,
    name: 'Armband mit Anhänger',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 9.JPG'),
    description: 'Armband mit Anhänger, 18Kt Roségold mit 9 schwarzen Diamanten von insgesamt 0,26 Karat an einer handgeknüpften schwarzen Kordel'
  },
  {
    id: 63,
    name: 'Armband mit Anhänger',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 10.JPG'),
    description: 'Armband mit Anhänger, 18Kt Roségold mit 9 Brillanten von insgesamt 0,25 Karat an einer handgeknüpften beigen Kordel'
  },
  {
    id: 64,
    name: 'Armband mit leerem Knopf',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 11.JPG'),
    description: 'Armband mit leerem Knopf, 18Kt Roségold an einer handgeknüpften schwarzen Kordel'
  },
  {
    id: 65,
    name: 'Armband mit Herz',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 12.JPG'),
    description: 'Armband mit Herz, 18Kt Roségold an einer handgeknüpften beigen Kordel'
  },
  {
    id: 66,
    name: 'Armband mit Peacezeichen',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 13.JPG'),
    description: 'Armband mit Peacezeichen, 18Kt Weißgold an einer handgeknüpften schwarzen Kordel'
  },
  {
    id: 67,
    name: 'Kettenarmband mit Schmetterling',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 14.JPG'),
    description: 'Kettenarmband mit Schmetterling, 18Kt Roségold '
  },
  {
    id: 68,
    name: 'Kettenarmband mit Kreuz',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 15.JPG'),
    description: 'Kettenarmband mit Kreuz, 18Kt Roségold mit 11 Brillanten von insgesamt 0,11 Karat '
  },
  {
    id: 69,
    name: 'Tennisarmband',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 16.JPG'),
    description: 'Tennisarmband, 18Kt Weißgold Schwarz rhodiniert mit schwarzen Diamanten, ist in verschiedenen Größen verfügbar'
  },
  {
    id: 70,
    name: 'Tennisarmband',
    category: 'Armbänder',
    image: require('./Armbänder/ArmbänderBilder/Armband 17.JPG'),
    description: 'Tennisarmband, 18Kt Weißgold mit Brillanten, ist in verschiedenen Größen verfügbar'
  },

];

const updatedProducts = products.map(product => {
  return {
    ...product,
    description: product.description.split(',').slice(1).join(',').trim()
  };
});

export default updatedProducts;
