import React, {useEffect} from "react";
import Trend_picture0 from '../assets/pictures/Trend/Trend0.JPG';
import Trend_picture1 from '../assets/pictures/Trend/Trend1.JPG';
import Trend_picture2 from '../assets/pictures/Trend/Trend2.jpg';
import CustomLink from "../assets/Custom_Elements/CustomLink";
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { easeInOut } from "framer-motion";

const Trend = ({ showOverlay, hideOverlay }) => {


  useEffect(() => {


    const timer = setTimeout(() => {
      if (hideOverlay.current) {
        hideOverlay.current();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div>
      <ParallaxProvider>
        <div className="content-main">
          <Parallax className="parallax-header" speed={0} easing={easeInOut}>

            <img src={Trend_picture0} className="parallax-image" alt="Hero" />
            <div className="mainsites-header-text trend">
              <h1>Trend</h1>
            </div>
          </Parallax>

          <Parallax speed={50} className="parallax-content" easing={easeInOut}>
            <div className="mainsiteswrapper">

              <section className="mainsites-content-section">
                <div className="content-section">
                  <div className="text-block-trend-header">
                    <h1>Zeitlose Meisterwerke</h1>
                    <p style={{ marginLeft: '7em' }}>
                      Schwelgen Sie in der Auswahl. Genießen Sie das Funkeln. Verlieben Sie sich in
                      jedes einzelne Schmuckstück. Aber glauben Sie bloß nicht das wäre alles! Saisonale
                      Kollektionen und Maßanfertigungen bringen Ihre Wünsche und unsere Kreativität
                      ans Tageslicht. Fordern Sie Anton Kerecz heraus!
                    </p>
                  </div>
                </div>
                <div className="content-section">
                  <CustomLink to="/trend-detail" showOverlay={showOverlay}>
                    <h1>2023</h1>
                  </CustomLink>
                  <div className="trend-image-container">
                    <CustomLink to="/trend-detail" showOverlay={showOverlay}>
                      <div className="image-block trend">
                        <img src={Trend_picture1} alt="Placeholder for artist" />
                      </div>
                    </CustomLink>
                    <div className="image-subtitles-trend">
                      <p>2023/09</p>
                      <p>Verlieben Sie sich in jedes einzelne Schmuckstück</p>
                    </div>
                  </div>
                </div>
                <div className="content-section">
                <CustomLink to="/trend-detail" showOverlay={showOverlay}>
                    <h1>2021</h1>
                  </CustomLink>
                  <div className="trend-image-container">
                  <CustomLink to="/trend-detail" showOverlay={showOverlay}>
                      <div className="image-block trend">
                        <img src={Trend_picture2} alt="Placeholder for artist" />
                      </div>
                    </CustomLink>
                    <div className="image-subtitles-trend">
                      <p>2021/09</p>
                      <p>Genießen Sie das Funkeln</p>
                    </div>
                  </div>
                </div>
                <div className="content-section">
                <CustomLink to="/trend-detail" showOverlay={showOverlay}>
                    <h1>2018</h1>
                  </CustomLink>
                  <div className="trend-image-container">
                  <CustomLink to="/trend-detail" showOverlay={showOverlay}>
                      <div className="image-block trend">
                        <img src={Trend_picture1} alt="Placeholder for artist" />
                      </div>
                    </CustomLink>
                    <div className="image-subtitles-trend">
                      <p>2018/11</p>
                      <p>Verlieben Sie sich in jedes einzelne Schmuckstück</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Parallax>
        </div>
      </ParallaxProvider>
    </div>
  );
};

export default Trend;
