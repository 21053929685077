import React from "react";
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import "./DesignMainSites.css";
import Artist_picture0 from '../assets/pictures/Artist/Artist_0.JPG';
import Artist_picture1 from '../assets/pictures/Artist/Artist_1.JPG';
import Artist_picture2 from '../assets/pictures/Artist/Artist_2.JPG';
import Artist_picture3 from '../assets/pictures/Artist/Artist_3.JPG';
import Artist_picture4 from '../assets/pictures/Artist/Artist_4.JPG';
import contentVideo from '../assets/videos/Kerecz_Final_4K.mp4';
import { easeInOut, easeOut } from "framer-motion";

const Artist = () => {
  return (
    <ParallaxProvider>
      <div className="content-main">

        <Parallax className="parallax-header" speed={0} easing={easeInOut} >
          <img src={Artist_picture0} alt="Hero" className="parallax-image" />
          <div className="mainsites-header-text">
            <h1>Artist</h1>
          </div>
        </Parallax>
        <Parallax speed={50} className="parallax-content" easing={easeInOut}>
          <div className="mainsiteswrapper">
            <section className="mainsites-content-section">
              <section className="content-section vertical-layout">
                <div className="text-block">
                  <h2>Leidenschaft und Kunst</h2>
                </div>

                <video className="content-video" controls>
                  <source src={contentVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </section>

              <section className="content-section">
                <div className="text-block">
                  <h2>Rise and Shine</h2>
                  <p>
                    Wir stehen auf Individualität. Deswegen ist jedes Meisterwerk von
                    Anton Kerecz persönlich in limitierter Stückzahl gefertigt. Das
                    garantiert Qualität, Exklusivität und die wohltuenden Energien, die
                    sich auf die Trägerin/ den Träger übertragen.
                  </p>
                  <p>
                    Um diesen Ansprüchen nachzukommen, arbeitet Anton Kerecz
                    ausschließlich mit 18 Karat Gold, in all seinen Nuancen -
                    Weißgold, Gelbgold, Rotgold - und edlen, handverlesenen Steinen.
                  </p>
                </div>
                <div className="image-block">
                  <img src={Artist_picture1} alt="Content 2" />
                </div>
              </section>

              {/* Rough Diamond Section */}
              <section className="content-section vertical-layout">
                <div className="image-block vertical">
                  <img src={Artist_picture2} alt="Rough Diamond" />
                </div>
                <div className="text-block">
                  <h2>Rough Diamond</h2>
                  <p>
                    Ein Stein. Eine Vision. Eine Skizze. Das sind die Anfänge eines
                    jeden großen Kunstwerks.
                  </p>
                  <p>
                    Mit Bedacht und Perfektion erweckt Anton Kerecz Gold zum Leben.
                  </p>
                </div>
              </section>

              <div className="yellowBackground">
                {/* Content Section 3 */}
                <section className="content-section yellow">
                  <div className="text-block">
                    <h2>Leidenschaft und Kunst</h2>
                    <p>
                      Aus dem einst jüngsten Goldschmiedemeister Österreichs, entwickelte
                      sich ein Künstler, der mit einer zielsicheren Leichtigkeit die
                      Brücke zwischen Klarheit und Extravaganz schafft.
                    </p>
                  </div>
                  <div className="image-block overflow">
                    <img src={Artist_picture3} alt="Placeholder for artist" className="overflow-image" />
                  </div>
                </section>
              </div>

              {/* Bottom Content Section */}
              <section className="content-section vertical-layout">
                <div className="image-block vertical">
                  <img src={Artist_picture4} alt="Placeholder for artist" />
                </div>
                <div className="text-block">
                  <h2>Leidenschaft und Kunst</h2>
                  <p>
                    Aus dem einst jüngsten Goldschmiedemeister Österreichs, entwickelte
                    sich ein Künstler, der mit einer zielsicheren Leichtigkeit die
                    Brücke zwischen Klarheit und Extravaganz schafft.
                  </p>
                </div>
              </section>
            </section>
          </div>
        </Parallax>
      </div>
    </ParallaxProvider>
  );
};

export default Artist;
