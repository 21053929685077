import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import './Menubar.css';
import CustomLink from './CustomLink';

const Menubar = ({ isVisible, isAtTop, showOverlay, shadowRef }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  // Get current path from React Router
  const location = useLocation();

  // Set isVisible to false if the current path is '/trend-detail'
  const shouldDisplayMenubar = isVisible && location.pathname !== '/trend-detail';

  // Function to handle showing the dropdown
  const showDropdown = () => {
    setDropdownVisible(true);

    // Check if shadowRef and its show method exist
    if (shadowRef && typeof shadowRef.show === 'function') {
      shadowRef.show();
    }
  };

  // Function to handle hiding the dropdown
  const hideDropdown = () => {
    setDropdownVisible(false);

    // Check if shadowRef and its hide method exist
    if (shadowRef && typeof shadowRef.hide === 'function') {
      shadowRef.hide();
    }
  };

  return (
    <div className={`menubar ${isAtTop ? 'transparent' : ''} ${!shouldDisplayMenubar ? 'transparent' : ''}`}>
      <div className={`menubar-container ${isDropdownVisible ? 'hovered' : ''} ${!shouldDisplayMenubar ? 'hidden' : ''} ${isAtTop ? 'transparent' : ''}`}>
        <nav className='menubar-nav'>
          <CustomLink to="/artist" showOverlay={showOverlay} onMouseEnter={hideDropdown}>Artist</CustomLink>
          <CustomLink to="/jewelryoverview" showOverlay={showOverlay} onMouseEnter={showDropdown}>Jewelry</CustomLink>
          <CustomLink to="/trend" showOverlay={showOverlay} onMouseEnter={hideDropdown}>Trend</CustomLink>
          <CustomLink to="/atelier" showOverlay={showOverlay} onMouseEnter={hideDropdown}>Atelier</CustomLink>
          <CustomLink to="/factory" showOverlay={showOverlay} onMouseEnter={hideDropdown}>Factory</CustomLink>
        </nav>
        {isDropdownVisible && (
          <div
            className="dropdown-menu"
            onMouseEnter={showDropdown}
            onMouseLeave={hideDropdown}
          >
            <CustomLink to="/jewelry?type=Ringe" showOverlay={showOverlay}>
              <span className="dropdown-index">01</span> Ringe
            </CustomLink>
            <CustomLink to="/jewelry?type=Halsketten" showOverlay={showOverlay}>
              <span className="dropdown-index">02</span> Halsketten
            </CustomLink>
            <CustomLink to="/jewelry?type=Ohrringe" showOverlay={showOverlay}>
              <span className="dropdown-index">03</span> Ohrringe
            </CustomLink>
            <CustomLink to="/jewelry?type=Armbänder" showOverlay={showOverlay}>
              <span className="dropdown-index">04</span> Armbänder
            </CustomLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menubar;
