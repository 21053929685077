import React from "react";
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import "./DesignMainSites.css";
import Jewelry_picture0 from '../assets/pictures/Jewelry/Jewelry0.jpg';
import Jewelry_picture1 from '../assets/pictures/Jewelry/Jewelry1.JPG';
import Jewelry_picture2 from '../assets/pictures/Jewelry/Jewelry2.JPG';
import Jewelry_picture3 from '../assets/pictures/Jewelry/Jewelry3.JPG';
import Jewelry_picture4 from '../assets/pictures/Jewelry/Jewelry4.JPG';
import Jewelry_picture5 from '../assets/pictures/Jewelry/Jewelry5.JPG';
import { easeInOut, easeOut } from "framer-motion";
import CustomLink from '../assets/Custom_Elements/CustomLink';

const JewerlyOverview = ({ showOverlay }) => {
    return (
        <ParallaxProvider>
            <div className="content-main">

                <Parallax className="parallax-header" speed={0} easing={easeInOut} >
                    <img src={Jewelry_picture0} alt="Hero" className="parallax-image" />
                    <div className="mainsites-header-text">
                        <h1>Jewelry</h1>
                    </div>
                </Parallax>
                <Parallax speed={50} className="parallax-content" easing={easeInOut}>
                    <div className="mainsiteswrapper">
                        <section className="mainsites-content-section">
                            <section className="content-section vertical-layout">
                                <div className="text-block">
                                    <h2>Pick and choose</h2>
                                </div>
                                <div className="image-block">
                                    <img src={Jewelry_picture1} alt="Content 2" />
                                </div>
                                <div className="text-block"><p>Schwelgen Sie in der Auswahl. Genießen Sie das Funkeln. Verlieben Sie sich in
                                    jedes einzelne Schmuckstück. Aber glauben Sie bloß nicht das wäre alles! Saisonale
                                    Kollektionen und Maßanfertigungen bringen Ihre Wünsche und unsere Kreativität
                                    ans Tageslicht. Fordern Sie Anton Kerecz heraus!</p>
                                </div>
                            </section>
                            <section className="content-section-jewelry">
                                <div className="jewelry-number">01.</div>
                                <div className="jewelry-content">
                                    <div className="image-block">
                                        <img src={Jewelry_picture2} alt="Content 2" />
                                    </div>
                                    <div className="text-block">
                                        <h2>Ringe</h2>
                                        <p>Aus dem einst jüngsten Goldschmiedemeister Österreichs,
                                            Wedding Band, Stacking, Eternity, Cocktail, Men’s, Signet
                                            die Brücke zwischen Klarheit und Extravaganz schafft.</p>
                                        <div className="jewelry-link">
                                            <CustomLink to="/jewelry?type=Ringe" showOverlay={showOverlay} >Übersicht</CustomLink>
                                        </div>
                                    </div>

                                </div>

                            </section>
                            <section className="content-section-jewelry">
                                <div className="jewelry-number">02.</div>
                                <div className="jewelry-content">
                                    <div className="image-block">
                                        <img src={Jewelry_picture3} alt="Content 2" />
                                    </div>
                                    <div className="text-block">
                                        <h2>Halsketten</h2>
                                        <p>Aus dem einst jüngsten Goldschmiedemeister Österreichs,
                                            Wedding Band, Stacking, Eternity, Cocktail, Men’s, Signet
                                            die Brücke zwischen Klarheit und Extravaganz schafft.</p>
                                        <div className="jewelry-link">
                                            <CustomLink to="/jewelry?type=Halsketten" showOverlay={showOverlay} >Übersicht</CustomLink>
                                        </div>
                                    </div>

                                </div>

                            </section>
                            <section className="content-section-jewelry">
                                <div className="jewelry-number">03.</div>
                                <div className="jewelry-content">
                                    <div className="image-block">
                                        <img src={Jewelry_picture4} alt="Content 2" />
                                    </div>
                                    <div className="text-block">
                                        <h2>Ohrringe</h2>
                                        <p>Aus dem einst jüngsten Goldschmiedemeister Österreichs,
                                            Wedding Band, Stacking, Eternity, Cocktail, Men’s, Signet
                                            die Brücke zwischen Klarheit und Extravaganz schafft.</p>
                                        <div className="jewelry-link">
                                            <CustomLink to="/jewelry?type=Ohrringe" showOverlay={showOverlay} >Übersicht</CustomLink>
                                        </div>
                                    </div>

                                </div>

                            </section>
                            <section className="content-section-jewelry">
                                <div className="jewelry-number">04.</div>
                                <div className="jewelry-content">
                                    <div className="image-block">
                                        <img src={Jewelry_picture5} alt="Content 2" />
                                    </div>
                                    <div className="text-block">
                                        <h2>Armbänder</h2>
                                        <p>Aus dem einst jüngsten Goldschmiedemeister Österreichs,
                                            Wedding Band, Stacking, Eternity, Cocktail, Men’s, Signet
                                            die Brücke zwischen Klarheit und Extravaganz schafft.</p>
                                        <div className="jewelry-link">
                                            <CustomLink to="/jewelry?type=Armbänder" showOverlay={showOverlay} >Übersicht</CustomLink>
                                        </div>
                                    </div>

                                </div>

                            </section>
                        </section>
                    </div>
                </Parallax>
            </div>
        </ParallaxProvider>
    );
};

export default JewerlyOverview;
