import React from "react";
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import "./DesignMainSites.css";
import Atelier_picture0 from '../assets/pictures/Atelier/Atelier0.JPG';
import Atelier_picture1 from '../assets/pictures/Atelier/Atelier1.JPG';
import Atelier_picture2 from '../assets/pictures/Atelier/Atelier2.jpg';
import Atelier_picture3 from '../assets/pictures/Atelier/Atelier3.jpg';
import Atelier_picture4 from '../assets/pictures/Atelier/Atelier4.JPG';
import Atelier_picture5 from '../assets/pictures/Atelier/Atelier5.jpg';
import { easeInOut, easeOut } from "framer-motion";

const Atelier = () => {
  return (
    <ParallaxProvider>
      <div className="content-main">
        <Parallax className="parallax-header" speed={0} easing={easeInOut}>
          <img src={Atelier_picture0} className="parallax-image" alt="Hero" />
          <div className="mainsites-header-text atelier">
            <h1>Atelier</h1>
          </div>
        </Parallax>

        <Parallax speed={50} className="parallax-content" easing={easeInOut}>
          <div className="mainsiteswrapper">
            <div className="mainsites-content-section">
              <section className="content-section vertical-layout">
                <div className="text-block">
                  <h2>Temple of Gold</h2>
                </div>
                <div className="image-block vertical">
                  <img src={Atelier_picture1} alt="Hero" />
                </div>
              </section>


              <section className="content-section">
                <div className="text-block">
                  <h2>Eleganz und Stil</h2>
                  <p>
                    Standort ist alles. Vor allem,
                    wenn es darum geht Besonderes
                    zu schaffen. So ist das Atelier
                    am persönlichen Kraftplatz des
                    Künstlers erbaut und bietet mit
                    seinem einzigartigen Design
                    eine ausnehmend angenehme,
                    elegante Atmosphäre.
                  </p>
                </div>
                <div className="image-block">
                  <img src={Atelier_picture2} alt="Content 2" />
                </div>
              </section>


              <section className="content-section vertical-layout">
                <div className="image-block vertical">
                  <img src={Atelier_picture3} alt="Rough Diamond" />
                </div>
                <div className="text-block">
                  <h2>In Ruhe gustieren
                  </h2>
                  <p>
                    Bei Wasser, Espresso oder Champagner können Sie hier in aller Ruhe gustieren, probieren
                    und Ihr Herz verlieren.
                  </p>
                </div>
              </section>

              <div className="yellowBackground atelier">

                <section className="content-section yellow">
                  <div className="text-block">
                    <h2>Leidenschaft und Kunst</h2>
                    <p>
                      Manchmal reichen Worte nicht, um Gefühle vollends zu
                      beschreiben. Da Bedarf es eines anderen Metiers: der
                      Kunst. Individuelle Anfertigungen schaffen diese Brücke
                      zwischen Herz und Ausdruck. Sprechen Sie mit Anton
                      Kerecz und er wird mit Ihnen das ultimative Design
                      entwickeln.
                    </p>
                  </div>
                  <div className="image-block overflow">
                    <img src={Atelier_picture4} alt="Placeholder for artist" className="overflow-image" />
                  </div>
                </section>
              </div>


              <section className="content-section vertical-layout">
                <div className="image-block vertical">
                  <img src={Atelier_picture5} alt="Placeholder for artist" />
                </div>
                <div className="text-block">
                  <h2>Leidenschaft und Kunst</h2>
                  <p style={{ maxWidth: "40%" }}>
                    Ein Schmuckstück von Anton Kerecz begleitet Sie ein Leben lang. Ob Hochzeit, Ball,
                    Arbeit, Reisen, im Garten oder Schaumbad - Sie zeigen Charakter.
                  </p>
                </div>
              </section>
            </div>
          </div>
        </Parallax>
      </div>
    </ParallaxProvider>
  );
};

export default Atelier;
