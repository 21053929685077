import React from 'react';
import './ProductPage.css';

const ProductPage = ({ id, products, isHidden }) => {

  // Find the product based on the ID
  const product = products.find((p) => p.id === parseInt(id, 10));

  if (!product) return <div className={`product-page ${isHidden ? 'hidden' : ''}`}></div>;

  return (
    <div className={`product-page ${isHidden ? 'hidden' : ''}`}>
      <img src={product.image} alt={product.name} className='product-img' />
      <div className='description-box'>
        <h1>{product.name}</h1>
        <p><b>Beschreibung und Details</b></p>
        <p>{product.description}</p>
      </div>
    </div>
  );
};

export default ProductPage;
