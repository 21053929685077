import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Menubar from './assets/Custom_Elements/Menubar.js';
import Artist from './subsites/Artist.js';
import Atelier from './subsites/Atelier.js';
import Factory from './subsites/Factory.js';
import Jewelry from './subsites/Jewelry.js';
import Trend from './subsites/Trend.js';
import Home from './subsites/Home.js';
import Footer from './subsites/Footer.js';
import Logo from './assets/Custom_Elements/Logo.js';
import Transition from './assets/Custom_Elements/Transition';
import Shadow from './assets/Custom_Elements/Shadow.js';
import JewelryOverview from './subsites/JewelryOverview.js';
import TrendDetail from './subsites/TrendDetail.js';

function App() {
  const [isMenubarVisible, setIsMenubarVisible] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const showedMenuMouse = useRef(false);

  // Create refs for the overlay control
  const showOverlay = useRef(null);
  const hideOverlay = useRef(null);
  const shadowRef = useRef(null);

  

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > lastScrollPosition && !showedMenuMouse.current) {
        setIsMenubarVisible(false); // Scrolling down - hide menubar
      } else if (!showedMenuMouse.current) {
        setIsMenubarVisible(true); // Scrolling up - show menubar
      }

      setIsAtTop(scrollPosition === 0);
      if (scrollPosition !== lastScrollPosition) {
        setLastScrollPosition(scrollPosition);
      }
    };

    const handleMouseMove = (e) => {
      const yPositionPercentage = (e.clientY / window.innerHeight) * 100;

      if (yPositionPercentage < 20 && isAtTop === false) { // Show menubar when the mouse is near the top
        setIsMenubarVisible(true);
        showedMenuMouse.current = true; // Set ref to indicate the mouse is in the top region
      } else if (yPositionPercentage > 30 && showedMenuMouse.current === true && isAtTop === false) {
        showedMenuMouse.current = false; // Mouse has moved away from the top region
        setIsMenubarVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [lastScrollPosition, isAtTop]);

  useEffect(() => {


    const timer = setTimeout(() => {
      if (hideOverlay.current) {
        hideOverlay.current();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const scrollToBottomAndBack = () => {
    // Scroll to the bottom of the page
    window.scrollTo({
      top: document.documentElement.scrollHeight, // Scroll height of the entire document
      behavior: 'auto', // Smooth scrolling
    });

    // After a delay, scroll back to the top
    setTimeout(() => {
      window.scrollTo({
        top: 0, // Scroll back to the top
        behavior: 'auto', // Smooth scrolling
      });
    }, 100); // Adjust the delay to control how long it stays at the bottom (in milliseconds)
  };


  
  return (
    <div className='App'>
      <Router>
        <Menubar
          isVisible={isMenubarVisible}
          isAtTop={isAtTop}
          showOverlay={showOverlay}
          shadowRef={shadowRef}
        />
        <Logo isMenubarVisible={isMenubarVisible} showOverlay={showOverlay} />
        <Transition showOverlay={showOverlay} hideOverlay={hideOverlay} />
        <Shadow shadowRef={shadowRef}></Shadow>
        <Routes>
          <Route path="/" element={<Home showOverlay={showOverlay} />} />
          <Route path="/artist" element={<Artist />} />
          <Route path="/atelier" element={<Atelier />} />
          <Route path="/factory" element={<Factory />} />
          <Route path="/jewelryoverview" element={<JewelryOverview showOverlay={showOverlay} />}/>
          <Route path="/jewelry" element={<Jewelry />}/>
          <Route path="/trend" element={<Trend showOverlay={showOverlay} hideOverlay={hideOverlay} />}  />
          <Route path="/trend-detail" element={<TrendDetail showOverlay={showOverlay} />} />
        </Routes>
      </Router>

      <Footer />
    </div>
  );
}

export default App;
