import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TrendDetail.css";
import TrendDetail_picture0 from '../assets/pictures/TrendDetail/TrendDetail0.JPG';
import TrendDetail_picture1 from '../assets/pictures/TrendDetail/TrendDetail1.JPG';
import TrendDetail_picture2 from '../assets/pictures/TrendDetail/TrendDetail2.JPG';
import TrendDetail_picture3 from '../assets/pictures/TrendDetail/TrendDetail3.JPG';
import TrendDetailButton from "../assets/Custom_Elements/TrendDetailButton";

const TrendDetail = ({showOverlay}) => {
  const [flyOut, setFlyOut] = useState(false);
  const navigate = useNavigate(); // React Router's hook for navigation

  const handleButtonClick = () => {
    setFlyOut(true);
    showOverlay.current();
    // Set a delay to navigate after the animation completes
    setTimeout(() => {
      navigate("/trend");
    }, 1000); // The duration should match the CSS transition time
  };

  return (
    <div className="trend-detail">
      <div className={`trend-detail-container ${flyOut ? 'fly-out' : ''}`}>
        <div className="trend-detail-content">
          <div className="detail-header">
            <p>2023 / 09</p>
            <h1>Verlieben Sie sich in jedes einzelne Schmuckstück</h1>
            <img src={TrendDetail_picture0} alt="Trend detail main"></img>
          </div>
          <div className="detail-image-collection">
            <img src={TrendDetail_picture1} alt="Trend detail 1" />
            <img src={TrendDetail_picture2} alt="Trend detail 2" />
            <img src={TrendDetail_picture3} alt="Trend detail 3" />
          </div>
          <div className="text-block">
            <p>Wir stehen auf Individualität. Deswegen ist jedes Meisterwerk von Anton Kerecz persönlich in
              limitierter Stückzahl gefertigt. Das garantiert Qualität, Exklusivität und die wohltuenden Energien, die
              sich auf die Trägerin/ den Träger übertragen. </p>
            <p>Um diesen Ansprüchen nachzukommen, arbeitet Anton Kerecz ausschließlich mit 18 Karat Gold, in all
              seinen Nuancen - Weißgold, Gelbgold, Rotgold - und edlen, handverlesenen Steinen.
              Ein Schmuckstück von Anton Kerecz begleitet Sie ein Leben lang. Ob Hochzeit, Ball, Arbeit, Reisen,
              im Garten oder Schaumbad - Sie zeigen Charakter.
            </p>
          </div>
          <TrendDetailButton onClick={handleButtonClick} />
        </div>
      </div>
    </div>
  );
};

export default TrendDetail;
